import Link from 'next/link';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import InstagramIcon from '@mui/icons-material/Instagram';
import FacebookIcon from '@mui/icons-material/Facebook';
import YoutubeIcon from '@mui/icons-material/YouTube';
import WhatsappIcon from '@mui/icons-material/WhatsApp';
import MailIcon from '@mui/icons-material/Mail';
import { getYear } from 'date-fns';
import { Box } from '@mui/material';
import { useLanguage } from '@/hooks/useLanguage';

export const Footer = () => {
  const { FormattedLang } = useLanguage()

  return (
    <Box className="absolute bottom-6 left-0 right-0 text-center text-small dark:text-slate-300 font-light">
      <div className="copyright text-gray-600 md:flex items-center justify-center mb-2 space-y-1 md:space-y-0">
        <Link href="/terms" className="text-primary hover:font-medium transition-all whitespace-nowrap">
          <FormattedLang id="terms" />
        </Link>
        <span className="mx-1">|</span>
        <Link href="/privacy" className="text-primary hover:font-medium transition-all whitespace-nowrap">
          <FormattedLang id="privacy" />
        </Link>
        <p className="ml-1">
          © {getYear(new Date())} 
          <Link 
            href="https://cogni2.com" 
            target="_blank" 
            title="Ir para o site da Cogni2"
            className="text-gray-600 mx-0.5"
            rel="noreferrer"
          >
            Cogni2.
          </Link> 
          <FormattedLang id="copyright" />
        </p>
      </div>
      <div className="social-media flex items-center justify-center gap-4">
        <Link 
          href="https://linkedin.com/company/cogni2" 
          title="Linkedin Cogni2"
          target="_blank"
          className="text-gray-600 hover:text-secondary transition"
        >
          <LinkedInIcon className="scale-75 hover:scale-90 transition" />
        </Link>
        
        <Link 
          href="https://www.youtube.com/@Cogni2" 
          title="Youtube Cogni2"
          target="_blank"
          className="text-gray-600 hover:text-secondary transition"
        >
          <YoutubeIcon className="scale-90 hover:scale-100 transition" />
        </Link>

        <Link 
          href="https://instagram.com/cogni2_official/" 
          title="Instagram Cogni2"
          target="_blank"
          className="text-gray-600 hover:text-secondary transition"
        >
          <InstagramIcon className="scale-75 hover:scale-90 transition" />
        </Link>

        <Link 
          href="https://www.facebook.com/cogni2official" 
          title="FACEBOOK Cogni2"
          target="_blank"
          className="text-gray-600 hover:text-secondary transition"
        >
          <FacebookIcon className="scale-75 hover:scale-90 transition" />
        </Link>

        <Link 
          href="https://wa.me/551148633136?text=Ol%C3%A1%2C%20gostaria%20de%20mais%20informa%C3%A7%C3%B5es%20sobre%20a%20Cogni2" 
          title="Whatsapp Cogni2"
          target="_blank"
          className="text-gray-600 hover:text-secondary transition"
        >
          <WhatsappIcon className="scale-75 hover:scale-90 transition" />
        </Link>

        <Link 
          href="mailto:contato@cogni2.com?subject=Gostaria%20de%20mais%20informa%C3%A7%C3%B5es%20sobre%20a%20Cogni2&body=Ol%C3%A1%2C%20gostaria%20de%20mais%20informa%C3%A7%C3%B5es%20sobre%20a%20Cogni2" 
          title="Email Cogni2"
          target="_blank"
          className="text-gray-600 hover:text-secondary transition"
        >
          <MailIcon className="scale-75 hover:scale-90 transition" />
        </Link>
      </div>
    </Box>
  )
}