import Link from "next/link";
import Image from 'next/image';
import { Box } from "@mui/material";

export const Award = () => {
  return (
    <Box className="award absolute top-0 right-4 md:right-6">
      <Link 
        href="https://revistapegn.globo.com/startups-to-watch/noticia/2024/10/conheca-as-100-startups-to-watch-2024.ghtml"
        target='_blank'
        rel='noreferrer'
        title="O 100 Startups to Watch é realizado por Pequenas Empresas & Grandes Negócios e por Época Negócios, em parceria com as consultorias Elogroup e Innovc... Clique e saiba mais >"
      >
        <Image 
          src="/images/selo-startup-contest.webp" 
          width={80}
          height={128}
          priority
          alt="Cogni2" 
          className="dark:filter dark:invert dark:brightness-0" 
        />
      </Link>
    </Box>
  );
}