'use client'

import React, { useState, useEffect, SyntheticEvent } from 'react';
import Image from 'next/image';
import Link from 'next/link';
import { AppWrapper } from '@/components/layouts/AppWrapper';
import { useRouter } from 'next/navigation';
import { Grid, Button, Typography, TextField, Paper, Stack, Box, Card, Alert, IconButton, CardActions, CardContent } from '@mui/material';
import ModalLoading from '@/components/widgets/modal/ModalLoading';
import LoginIcon from '@mui/icons-material/Login';
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';
import InputAdornment from '@mui/material/InputAdornment';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import { debounce } from "@/helpers/utils";
import { useLanguage } from '@/hooks/useLanguage';
import { SignInResponse, signIn } from 'next-auth/react';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import Typewriter from 'typewriter-effect';
import { Footer } from '@/components/layouts/Footer';
import { Award } from '@/components/base/Award';

export default function Login() {
  const [userName, setUserName] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [errorMessage, setErrorMessage] = useState<string | undefined>();
  const [infoMessage, setInfoMessage] = useState<string | undefined>();
  const [loading, setLoading] = useState<boolean>(false);
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const { lang, FormattedLang } = useLanguage()

  const router = useRouter();

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  const handleChangePassword = () => {
    router.push("/password_login")
  }

  useEffect(() => {
    const logoutMessage = localStorage.getItem('logoutMessage')
    const infoMessage = localStorage.getItem('infoMessage')

    if (logoutMessage) {
      setErrorMessage(logoutMessage)
      setInfoMessage('')
    }

    if (infoMessage) {
      setInfoMessage(infoMessage)
      setErrorMessage('')
    }

    localStorage.removeItem('logoutMessage')
    localStorage.removeItem('infoMessage')

    return () => {
      setInfoMessage('')
      setErrorMessage('')
    }
  }, []);  

  useEffect(() => {
    if (errorMessage || infoMessage) {
      setLoading(false)
    }
  }, [errorMessage, infoMessage])

  const handleSubmit = async (event: SyntheticEvent) => {
    event.preventDefault()
    setErrorMessage('')
    setInfoMessage('')

    try {
      if (!userName || !password) {
        setErrorMessage('Por favor preencha o login e senha corretamente')
        setInfoMessage('')
        return;
      }
  
      setLoading(true)
  
      const result: SignInResponse | undefined = await signIn('credentials', {
        userName,
        password,
        redirect: false
      });

      if(result?.ok){
        router.replace('/home')
      }else{
        setErrorMessage('Ocorreu um erro durante o login. Confira novamente seu login e senha');
        setInfoMessage('')
      }
    } catch (error) {
      console.error('Ocorreu um erro durante o login: ', error);
      setErrorMessage('Ocorreu um erro durante o login. Tente novamente');
      setInfoMessage('')
    } finally {
      debounce(() => {
        setLoading(false)
        setErrorMessage('')
        setInfoMessage('')
      }, 2000)
    }
  }

  const isSubmitDisabled: boolean = loading || !password || !userName

  return (
    <AppWrapper login className="p-0">
      <Grid container className="h-screen w-screen relative">
        <Award />
        <Grid item xs={12} className="flex items-center justify-center bg-slate-200 bg-login bg-repeat bg-right-top md:bg-center bg-contain md:bg-cover dark:bg-none dark:bg-slate-900">
          <Stack flexWrap="wrap" className="text-center m-4 md:m-0">
            <Box className="mx-auto mb-4">
              <Image 
                src="/images/cogni2.svg" 
                height={120} 
                width={120} 
                priority
                alt="Cogni2" 
                className="dark:filter dark:invert dark:brightness-0" 
              />
            </Box>
            <Box>
              <Typography variant="h6" component="div" className="bg-primary dark:bg-slate-700 rounded-t-lg py-1 text-white dark:text-slate-200 text-small md:text-xs font-medium">
                <Typewriter
                  options={{
                    strings: [
                      lang("login_title_1"), 
                      lang("login_title_2"),
                      lang("login_title_3"),
                      lang("login_title_4"),
                      lang("login_title_5"),
                    ],
                    autoStart: true,
                    loop: true,
                    delay: 30,
                    // @ts-ignore
                    pauseFor: 3000,
                    deleteSpeed: 10,
                    cursor: '•'
                  }}
                />
              </Typography>
            </Box>

            {infoMessage ? 
              <Alert 
                onClose={() => setInfoMessage('')} 
                severity="info"
                className="text-small font-medium"
              >
                {infoMessage}
              </Alert>
            : ''}
            
            {errorMessage ? 
              <Alert 
                onClose={() => setErrorMessage('')} 
                severity="error"
                className="text-small font-medium"
              >
                {errorMessage}
              </Alert>
            : ''}
            
            <Card className="dark:bg-slate-800 pt-1 mt-0 md:min-w-100 relative z-2">
              <CardContent sx={{ p: 3, pb: 0 }}>
                <Paper elevation={0} sx={{ mb: 2 }}>
                  <TextField 
                    fullWidth 
                    type="text"
                    name="email"
                    required 
                    autoFocus
                    id="emailInput" 
                    onChange={(e) => setUserName(e.target.value)} 
                    label="Email" 
                    variant="outlined" 
                    placeholder="Email"
                    InputProps={{
                      className: "dark:bg-slate-700 dark:text-slate-200 dark:border-slate-600",
                      readOnly: loading,
                      endAdornment: (
                        <InputAdornment position="end">
                          <AlternateEmailIcon fontSize="small" className="dark:text-slate-200" />
                        </InputAdornment>
                      ),
                    }}
                    InputLabelProps={{
                      className: "dark:text-slate-300",
                    }}
                  />
                </Paper>
                <Paper elevation={0} sx={{ mb: 2 }}>
                  <TextField
                    fullWidth
                    required
                    type={showPassword ? 'text' : 'password'}
                    name="password"
                    InputProps={{
                      className: "dark:bg-slate-700 dark:text-slate-200 dark:border-slate-600",
                      readOnly: loading,
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                          >
                            {showPassword ? <VisibilityOff fontSize="small" className="dark:text-slate-200" /> : <Visibility fontSize="small" className="dark:text-slate-200" />}
                          </IconButton>
                        </InputAdornment>
                      )
                    }}
                    InputLabelProps={{
                      className: "dark:text-slate-300",
                    }}
                    onChange={(e) => setPassword(e.target.value)}
                    placeholder={lang('input_password')}
                    label={lang('input_password')}
                    variant="outlined"
                  />
                </Paper>
              </CardContent>
              <CardActions sx={{ p: 3 }}>
                <Stack spacing={{ xs: 1 }} sx={{ width: '100%' }}>
                  <Button 
                    type="submit" 
                    className="w-full flex gap-2 mb-2 bg-secondary min-w-auto disabled:opacity-60 disabled:text-white" 
                    variant="contained" 
                    disabled={isSubmitDisabled}
                    size="large" 
                    onClick={handleSubmit}
                  >
                    <LoginIcon fontSize="small" /> 
                    <FormattedLang id="input_login" />
                  </Button>
                  <Box className="login-more-actions md:flex justify-center md:justify-between items-center">
                    <Button 
                      type="button" 
                      className="flex justify-center items-center w-full md:w-auto text-center gap-0.5 text-primary hover:bg-transparent hover:font-medium transition-all dark:text-slate-500 normal-case min-w-auto font-light text-small py-3 mt-0 whitespace-nowrap" 
                      variant="text" 
                      size="small"
                      disabled={loading}
                      onClick={handleChangePassword}
                    >
                      <LockOpenIcon fontSize="small" /> 
                      <FormattedLang id="forget.password" />
                    </Button>
                    <Box className="flex items-center justify-center md:justify-between whitespace-nowrap">
                      <Typography variant="body2" className="text-small dark:text-slate-300 font-light">
                        <FormattedLang id="no.account" />
                      </Typography>
                        <Link 
                          href="https://api.whatsapp.com/send/?phone=551148633136&text=Ol%C3%A1%2C+gostaria+de+criar+uma+conta+para+acessar+o+app+da+Cogni2%2C+como+fa%C3%A7o%3F&type=phone_number&app_absent=0"
                          target='_blank'
                          rel='noreferrer'
                          className="text-small text-primary hover:font-medium transition-all dark:text-slate-300 font-light ml-1" 
                        >
                          <FormattedLang id="register" /> <KeyboardArrowRightIcon />
                        </Link>
                    </Box>
                  </Box>
                </Stack>
              </CardActions>
            </Card>
          </Stack>
          <Footer />
        </Grid>
      </Grid>
      {loading && 
        <ModalLoading open={loading} handleClose={() => setLoading(false)} />
      }
    </AppWrapper>
  );
}