import React, { useState, useEffect } from 'react';
import Image from 'next/image';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import Container from '@mui/material/Container';
import { cyclicTextGenerator } from '@/helpers/utils';
import { useLanguage } from '@/hooks/useLanguage';
import { worker } from "@/helpers/worker";
import Button from '@mui/material/Button';

interface ModalLoadingProps {
  open: boolean;
  handleClose: () => void;
  text?: string;
}

const messages: string[] = ['login_loading_1', 'login_loading_2'];

export default function ModalLoading({ open, handleClose, text }: ModalLoadingProps) {
  const [message, setMessage] = useState<string>();
  const { currentLang, FormattedLang } = useLanguage()

  useEffect(() => {
    const titleGenerator = cyclicTextGenerator(messages);

    const newWorker = new Worker(worker);
    newWorker.postMessage({ turn: "on" })

    let lastTimestamp: number;
    const animate = (timestamp: number) => {
      if (!lastTimestamp || timestamp - lastTimestamp >= 5000) {
        setMessage(titleGenerator.next().value || "")
        lastTimestamp = timestamp;
      }

      requestAnimationFrame(animate);
    };
    const animationId = requestAnimationFrame(animate);

    return () => {
      cancelAnimationFrame(animationId);
      newWorker.postMessage({ turn: "off" });
      newWorker.terminate();
    }
  }, [currentLang])

  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        sx={{
          "html:not(.dark) & .MuiDialog-container": {
            backgroundColor: 'rgba(255,255,255,.92)',
          },
          "& .MuiPaper-root": {
            width: "100%",
            maxWidth: "500px",
            borderRadius: "0",
            backgroundColor: 'transparent',
            boxShadow: 0
          }
        }}
        className="dark:bg-hover/90"
      >
        <DialogContent>
          <Container>
            <div className="flex flex-col flex-auto justify-center items-center pt-4">
              <div className="animate-bounce dark:filter dark:invert dark:brightness-0">
                <Image 
                  src="/images/cogni2-icon.png" 
                  alt="Cogni2" 
                  width="70" 
                  height="60" 
                  priority
                />
              </div>
              <div className="chat-typing">
                {text ? 
                  <p className="flex text-small items-center mt-3 leading-10 font-light text-slate-800 dark:text-slate-200">
                    <small>{text}</small> 
                    <Button variant="text" title="Fechar" onClick={handleClose} className="text-slate-800 text-xl ml-2">X</Button>
                  </p> : 
                  <p>
                    <span className="text-sm font-light whitespace-nowrap text-slate-800 dark:text-slate-200">
                      {message && <FormattedLang id={message} />}
                    </span>
                  </p>
                }
              </div>
            </div>
          </Container>
        </DialogContent>
      </Dialog>
    </>
  );
}
