export const workerFn = () => {
  let requestId;
  let time = 0;

  const update = () => {
    time += 1;
    self.postMessage({ time });
    requestId = requestAnimationFrame(update);
  };

  self.onmessage = function ({ data: { turn } }) {
    if (turn === "off" || requestId) {
      cancelAnimationFrame(requestId);
      time = 0;
    }
    if (turn === "on") {
      requestId = requestAnimationFrame(update);
    }
  };
};

let code = workerFn.toString();
code = code.substring(code.indexOf("{") + 1, code.lastIndexOf("}"));

const blob = new Blob([code], { type: "application/javascript" });
export const worker = URL.createObjectURL(blob);
